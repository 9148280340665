import { render, staticRenderFns } from "./EditGallery.vue?vue&type=template&id=b5f11dda&scoped=true"
import script from "./EditGallery.vue?vue&type=script&lang=ts"
export * from "./EditGallery.vue?vue&type=script&lang=ts"
import style0 from "./EditGallery.vue?vue&type=style&index=0&id=b5f11dda&prod&lang=css"
import style1 from "./EditGallery.vue?vue&type=style&index=1&id=b5f11dda&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5f11dda",
  null
  
)

export default component.exports