<template>
  <b-col>
    <b-row>
      <b-button @click="modalEditor()" variant="success"
        >Nueva noticia</b-button
      >
    </b-row>
    <b-row v-if="isUpload && items.length == 0">
      <h5 class="mt-5" style="position: absolute; z-index: 1">
        No hay noticias creadas
      </h5>
    </b-row>
    <b-row v-if="isUpload">
      <b-card id="tableNews" class="table-responsive">
        <b-table sticky-header responsive :items="items">
          <template v-slot:cell(Noticia)="data">
            <span v-html="data.value"></span>
          </template>
          <template v-slot:cell(FechaDePublicacion)="data">
            <p>{{ getDate(data.value) }}</p>
          </template>
          <template v-slot:cell(galeria)="data">
            <p>{{ getGallery(data.value) }}</p>
          </template>
          <template v-slot:cell(imagenDePortada)="data">
            <img :src="base + '/img/gallery/' + data.value" height="150px" />
          </template>
          <template v-slot:cell(edicionNew)="data">
            <div class="d-flex">
              <b-button
                class="m-1"
                variant="warning"
                @click="modalEditor(data.value)"
                >Editar</b-button
              >
              <b-button
                class="m-1"
                @click="deleteNew(data.value)"
                variant="danger"
                >Eliminar</b-button
              >
            </div>
          </template>
          <template v-slot:cell(prenoticia)="data">
            <span v-html="data.value"></span>
          </template>
          <template v-slot:cell(imagenesNoticia)="data">
            <div style="display: grid; grid-template-columns: repeat(4, 1fr)">
              <img
                v-for="(element, key) in data.value"
                :key="key"
                height="100px"
                :src="base + '/img/minGallery/' + element"
                alt=""
              />
            </div>
          </template>
        </b-table>
      </b-card>
    </b-row>
    <b-row v-else>
      <div class="loadingLogo">
        <b-spinner></b-spinner>
      </div>
    </b-row>
    <editorDeNoticias
      v-if="datosCargados"
      @changeNews="changeNews"
      :noticia="getNews"
      :galerias="galerias"
      modalId="editorDeNoticias"
      @closeModal="datosCargados = false"
    />
  </b-col>
</template>

<script>
import axios from "@/directives/axios.js";
import editorDeNoticias from "@/components/dashboard/ModalEditorDeNoticias.vue";

export default {
  name: "panelNoticias",
  props: [],
  components: {
    editorDeNoticias,
  },
  data() {
    return {
      datosCargados: false,
      items: [],
      galerias: [],
      getNews: undefined,
      isUpload: false,
      base: location.protocol + "//" + location.hostname,
    };
  },
  watch: {},
  methods: {
    modalEditor(idNoticia) {
      if (idNoticia) {
        this.getNews = this.items.find((element) => element.Id === idNoticia);
      } else {
        this.getNews = undefined;
      }
      this.datosCargados = true;
      this.$nextTick(() => {
        this.$bvModal.show("editorDeNoticias");
      });
    },
    deleteNew(idNew) {
      this.$bvModal
        .msgBoxConfirm("¿Seguro que desea eliminar esta noticia?", {
          size: "sm",
          centered: true,
          footerClass: "justify-content-center",
          okTitle: "Si",
          cancelTitle: "No",
        })
        .then((option) => {
          if (option) {
            axios({
              method: "DELETE",
              url: `/noticias/eliminarNoticia?id=${idNew}`,
              withCredentials: true,
            })
              .then((value) => {
                let okValue = "Noticia Eliminada Correctamente";
                let notOkValue =
                  "Ocurrio un error durante el proceso de eliminación.";

                this.$bvModal
                  .msgBoxOk(value.status === 200 ? okValue : notOkValue, {
                    size: "sm",
                    centered: true,
                    footerClass: "justify-content-center",
                    okTitle: "Aceptar",
                  })
                  .then(() => {
                    this.changeNews();
                  });
              })
              .catch(() => {});
          }
        });
    },
    getGallery(idGallery) {
      if (this.galerias) {
        let galeria = this.galerias.filter(
          (galeria) => galeria.id === idGallery
        );

        if (galeria.length > 0) {
          return galeria[0].titulo;
        } else {
          return "Galeria No asociada";
        }
      }
    },
    getDate(data) {
      let day = new Date(data).getDate();
      let month = new Date(data).getMonth() + 1;
      let year = new Date(data).getFullYear();
      return day + "-" + month + "-" + year;
    },
    changeNews() {
      this.isUpload = false;
      axios({
        method: "GET",
        url: `/imagenes/todasGalerias`,
      })
        .then((data) => {
          this.galerias = data.data.todasGalerias;
          axios({
            method: "GET",
            url: `/noticias/todasNoticias`,
            withCredentials: true,
          })
            .then((data) => {
              this.items = [];
              data.data.todasNoticias.forEach((element) => {
                this.items.push({
                  Id: element.id,
                  Titulo: element.titulo,
                  Noticia: element.prenoticia,
                  FechaDePublicacion: element.fechapublicacion,
                  galeria: element.idgaleria,
                  imagenDePortada: element.imagenportada,
                  edicionNew: element.id,
                  prenoticia: element.htmlText,
                  imagenesNoticia:
                    element.imagenesNoticia.length == 0
                      ? []
                      : element.imagenesNoticia.map(
                          (element) => element.nombre_imagen
                        ),
                });
              });
              this.isUpload = true;
              this.items.reverse();
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.changeNews();
  },
  computed: {},
};
</script>
<style scoped>
.b-table-sticky-header {
  max-height: 100%;
}
#tableNews {
  height: calc(91vh);
}
#tableNews .card-body {
  padding: 0;
  height: 100%;
}
#createNews {
  background: black;
}
.row {
  width: 100%;
  margin: 0;
}
</style>
