<template>
  <b-modal
    class="popupNews"
    id="editGallery"
    :hide-footer="false"
    :hide-header="false"
  >
    <template v-slot:modal-header>
      <div class="d-flex">
        <h2 v-if="dataGallery.id === '0'">Nueva Galeria</h2>
        <h2 v-else>Editar Galeria</h2>
      </div>
      <div class="pointer justify-self-center">
        <closeicon @click="closeModal()" />
      </div>
    </template>
    <div class="d-flex m-2">
      <div class="m-2">
        <b-row class="my-1">
          <b-col class="p-1" sm="3">
            <label for="input-none">Titulo:</label>
          </b-col>
          <b-col sm="9">
            <b-form-input
              id="input-none"
              :state="tituloState"
              placeholder="Titulo"
              v-model="titulo"
            ></b-form-input>
          </b-col>
        </b-row>
      </div>
      <div class="m-2">
        <div class="cardViewconfconf">
          <div id="imgChangeImage" class="hoverr-2 text-white rounded">
            <img
              @click="openSelectorImages"
              style="width: 100%; height: 150px"
              :src="prefill"
            />
          </div>
        </div>
      </div>
      <selectorImagenes @imagen="remplaceImage" id="editarGaleriaEditGal" />
    </div>
    <template v-slot:modal-footer>
      <b-button
        :disabled="!activeModificarGaleria"
        @click="saveGallery()"
        variant="success"
        >{{
          dataGallery.id === "0" ? "Crear Galeria" : "Modificar Galeria"
        }}</b-button
      >
      <b-button @click="$bvModal.hide('editGallery')" variant="danger"
        >Cancelar</b-button
      >
    </template>
  </b-modal>
</template>

<script lang="ts">
import closeicon from "../../assets/components/close.vue";
import selectorImagenes from "../dashboard/SelectorImages.vue";
import axios from "@/directives/axios.js";

export default {
  name: "editGallery",
  props: ["dataGallery"],

  components: {
    closeicon,
    selectorImagenes,
  },

  data() {
    return {
      titulo: "",
      imagenportada: "", //La imagen que nos viene y se pinta
      base:
        location.protocol +
        "//" +
        location.hostname
    };
  },
  mounted() {
    if (this.dataGallery) {
      this.titulo = this.dataGallery.tituloNoticia;
      this.imagenportada = this.dataGallery.imageSrc;
    }
  },
  deactivated() {
    this.titulo = "";
    this.imagenportada = ""; //La imagen que nos viene y se pinta
  },
  watch: {
    dataGallery: function () {
      this.titulo = this.dataGallery.tituloNoticia;
      this.imagenportada = this.dataGallery.imageSrc;
    },
  },
  computed: {
    activeModificarGaleria() {
      return this.tituloState && this.imagenportada;
    },
    tituloState() {
      return this.titulo && this.titulo.length > 0 ? true : false;
    },

    //La imagen de la portada que se pinta.
    prefill() {
      return this.imagenportada
        ? `${this.base}/img/minGallery/` + this.imagenportada
        : require("@/assets/img/icon/plus-svgrepo-com.svg");
    },
  },
  methods: {
    openSelectorImages() {
      this.$nextTick(() => {
        this.$bvModal.show("editarGaleriaEditGal");
      });
    },
    remplaceImage(value) {
      this.imagenportada = value[0].imagen;
    },
    saveGallery() {
      axios({
        method: "POST",
        url: `/imagenes/nuevaGaleria`,
        withCredentials: true,
        data: {
          id: this.dataGallery ? this.dataGallery.id : null,
          titulo: this.titulo,
          imagenportada: this.imagenportada,
        },
      })
        .then((data) => {
          console.log(data.data.todasGalerias);
          this.$emit("updateGalleries", data.data.todasGalerias);
          this.closeModal();
        })
        .catch(() => {});
    },
    //Arranca el input (oculto)
    changeImage() {
      document.getElementById("uploadImage").click();
    },
    closeModal() {
      this.$bvModal.hide("editGallery");
    },
  },
};
</script>
<style>
#header-contact {
  border-bottom: 1px solid;
}
.justify-content-end {
  margin-right: 0px;
}
#imgChangeImage :hover {
  cursor: pointer;
  zoom: 1.1;
  transition: 0.5s;
}
</style>
<style scoped>
.d-flex {
  margin: 0 auto !important;
}
</style>
