<template>
  <b-modal
    scrollable
    class="popupNews"
    size="xl"
    :id="modalId"
    :hide-footer="false"
    :hide-header="false"
  >
    <template v-slot:modal-header>
      <div class="d-flex">
        <h2>{{ noticia ? "Editar Noticia" : "Nueva Noticia" }}</h2>
      </div>
      <div class="pointer justify-self-center">
        <closeicon @click="closeModal()" />
      </div>
    </template>
    <b-row id="editor">
      <b-col class="headerEditor">
        <b-row style="margin: 10px">
          <h5>Título:</h5>
          <b-form-input v-model="titleNews" placeholder="titulo"></b-form-input>
        </b-row>
        <b-row style="margin: 10px">
          <h5>Fecha de la Noticia:</h5>
          <b-form-datepicker
            locale="es"
            id="fechaNoticia"
            v-model="fechaNoticia"
            class="mb-2"
          ></b-form-datepicker
        ></b-row>
        <b-row>
          <h5>¿Vinculada a una galeria?:</h5>
          <b-form-checkbox
            id="checkbox-1"
            v-model="haveGallery"
            name="checkbox-1"
          >
            <p style="margin-left: 5px">
              {{ haveGallery ? " SI" : " NO" }}
            </p>
          </b-form-checkbox>
          <b-form-select
            v-if="haveGallery"
            v-model="gallerySelected"
            :options="galleryOptions"
          >
            <template #first>
              <b-form-select-option value="" disabled
                >-- Seleccione una galeria --</b-form-select-option
              >
            </template></b-form-select
          >
        </b-row>
        <b-row v-if="modalId == 'editorDeNoticiasCaritas'">
          <h5>¿Quiere que la noticia sea visibe en la pagina principal?:</h5>
          <b-form-checkbox
            id="checkbox-1"
            v-model="paginaprincipal"
            name="checkbox-1"
            :value="1"
            :unchecked-value="0"
          >
            Página principal
          </b-form-checkbox>
        </b-row>
      </b-col>
      <b-col>
        <b-row id="imgChangeImage" class="hoverr-2 text-white rounded">
          <img
            @click="$bvModal.show('imagenportadaNoticia')"
            style="height: 150px"
            :src="prefill"
          />
        </b-row>
      </b-col>
      <b-col>
        <b-row id="imgChangeImage" class="hoverr-2 text-white rounded">
          <b-icon
            class="mt-6"
            scale="5"
            variant="info"
            @click="$bvModal.show('imagenesNoticia')"
            icon="images"
          ></b-icon>
        </b-row>
      </b-col>
      <b-form-input
        v-model="prenoticia"
        placeholder="Introducción de la Noticia"
      ></b-form-input>
      <editor-de-texto
        :htmlForEditor="htmlForEditor"
        :obtenerCambiosHtml="obtenerCambiosHtml"
        @nuevoHtml="nuevoHtml"
      ></editor-de-texto>
    </b-row>
    <keep-alive>
      <selectorImagenes
        @imagen="remplaceImagePortada"
        id="imagenportadaNoticia"
      />
    </keep-alive>
    <keep-alive>
      <selectorImagenes
        @imagen="imagenesNoticia"
        :dataGallery="imagenesnoticiaSeleccionada"
        id="imagenesNoticia"
        :multiSelect="true"
      />
    </keep-alive>
    <template v-slot:modal-footer>
      <div class="pointer justify-content-end">
        <b-button variant="success" @click="saveNews">Guardar</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script lang="ts">
import editorDeTexto from "./EditorDeTexto.vue";
import closeicon from "@/assets/components/close.vue";
import axios from "@/directives/axios.js";
import selectorImagenes from "./SelectorImages.vue";
// import pictureInput from "vue-picture-input";

export default {
  name: "editorDeNoticias",

  components: {
    editorDeTexto,
    closeicon,
    selectorImagenes,
  },
  data() {
    return {
      obtenerCambiosHtml: true,
      gallerySelected: 0,
      fechaNoticia: undefined,
      haveGallery: false,
      titleNews: undefined,
      imagePortada: undefined,
      prenoticia: undefined,
      htmlForEditor: "<div></div>",
      imagenesnoticia: undefined,
      paginaprincipal: 0,
      base:
        location.protocol +
        "//" +
        location.hostname
    };
  },
  props: ["modalId", "galerias", "noticia"],
  mounted() {
    this.$store.dispatch("changeAllImages");
    if (this.noticia) {
      this.prenoticia = this.noticia.Noticia;
      this.titleNews = this.noticia.Titulo;
      this.date = this.noticia;
      let day = new Date(this.noticia.FechaDePublicacion).getDate();
      let month = new Date(this.noticia.FechaDePublicacion).getMonth() + 1;
      let year = new Date(this.noticia.FechaDePublicacion).getFullYear();
      this.imagenesnoticia = this.noticia.imagenesNoticia;
      this.paginaprincipal = this.noticia.paginaprincipal;
      let fecha = year + "-" + month + "-" + day;
      this.fechaNoticia = fecha;
      if (this.noticia.galeria != 0) {
        this.haveGallery = true;
        this.gallerySelected = this.noticia.galeria;
      } else {
        this.haveGallery = false;
        this.gallerySelected = this.noticia.galeria;
      }
      this.htmlForEditor = this.noticia.prenoticia;
      this.imagePortada = this.noticia.imagenDePortada;
    }
  },
  computed: {
    imagenesnoticiaSeleccionada() {
      console.log(this.imagenesnoticia);
      return this.imagenesnoticia ? this.imagenesnoticia : [];
    },
    prefill() {
      if (this.imagePortada) {
        return this.base + "/img/minGallery/" + this.imagePortada;
      } else {
        return require("@/assets/img/icon/plus-svgrepo-com.svg");
      }
    },
    galleryOptions() {
      console.log(this.galerias);
      let gallery = [];
      if (this.galerias && this.galerias.length > 0) {
        this.galerias.forEach((element) => {
          gallery.push({ value: element.id, text: element.titulo });
        });
      }
      return gallery;
    },
  },
  methods: {
    nuevoHtml(value) {
      this.htmlForEditor = value;
      switch (this.modalId) {
        case "editorDeNoticiasCaritas":
          this.actualizarNoticiaCaritas();
          break;
        case "editorDeNoticias":
          this.actualizarNoticia();
          break;

        default:
          break;
      }
    },
    remplaceImagePortada(value) {
      console.log(value);
      this.imagePortada = value[0].imagen;
    },
    imagenesNoticia(value) {
      console.log(value);
      this.imagenesnoticia = value.map((element) => element.imagen);
    },
    actualizarNoticiaCaritas() {
      axios({
        method: "POST",
        url: `/noticiasCaritas/editarNoticia`,
        withCredentials: true,
        data: {
          id: this.noticia ? this.noticia.Id : null,
          titulo: this.titleNews,
          prenoticia: this.prenoticia ? this.prenoticia : "",
          htmlText: this.htmlForEditor,
          fechapublicacion: new Date(this.fechaNoticia),
          imagenportada: this.imagePortada,
          imagenesNoticia: this.imagenesnoticia,
          paginaprincipal: this.paginaprincipal,
          galeria: this.gallerySelected ? this.gallerySelected : 0,
        },
      }).then((value) => {
        let okValue = "Noticia guardada Correctamente";
        let notOkValue =
          "Ocurrio un error durante el proceso de actualizacion, revise la noticia.";

        this.$bvModal
          .msgBoxOk(value.status === 200 ? okValue : notOkValue, {
            size: "sm",
            centered: true,
            footerClass: "justify-content-center",
            okTitle: "Aceptar",
          })
          .then(() => {
            this.closeModal();
            this.$emit("changeNews", true);
          });
      }).catch(()=>{});
    },
    actualizarNoticia() {
      axios({
        method: "POST",
        url: `/noticias/editarNoticia`,
        withCredentials: true,
        data: {
          id: this.noticia ? this.noticia.Id : null,
          titulo: this.titleNews,
          prenoticia: this.prenoticia ? this.prenoticia : "",
          htmlText: this.htmlForEditor,
          fechapublicacion: new Date(this.fechaNoticia),
          imagenportada: this.imagePortada,
          imagenesNoticia: this.imagenesnoticia,
          galeria: this.gallerySelected ? this.gallerySelected : 0,
        },
      }).then((value) => {
        let okValue = "Noticia guardada Correctamente";
        let notOkValue =
          "Ocurrio un error durante el proceso de actualizacion, revise la noticia.";

        this.$bvModal
          .msgBoxOk(value.status === 200 ? okValue : notOkValue, {
            size: "sm",
            centered: true,
            footerClass: "justify-content-center",
            okTitle: "Aceptar",
          })
          .then(() => {
            this.closeModal();
            this.$emit("changeNews", true);
          });
      }).catch(()=>{});
    },
    saveNews() {
      this.$bvModal
        .msgBoxConfirm("¿Seguro que desea guardar los cambios?", {
          size: "sm",
          centered: true,
          footerClass: "justify-content-center",
          okTitle: "Si",
          cancelTitle: "No",
        })
        .then((chechResponse) => {
          if (chechResponse) {
            this.obtenerCambiosHtml = !this.obtenerCambiosHtml;
          }
        });
    },
    closeModal() {
      this.$emit("closeModal");
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
<style>
.pointer :hover {
  cursor: pointer;
}
.timeHour {
  padding: 0;
  margin: 0;
}
.custom-color-menu {
  flex-wrap: wrap;
  min-width: 152px;
  width: 152px;
  max-width: 152px;
}
.btn-group,
btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: inline-flex;
  vertical-align: middle;
}
@media screen and (min-width: 990px) {
  .headerEditor {
    /* display: flex; */
  }
}
</style>
<style scoped>
.d-flex {
  margin: 0 auto !important;
}
#imgChangeImage {
  padding-top: 90px !important;
}
</style>
