<template>
  <div id="bodyDashboard">
    <div v-if="lista.length > 0" class="wrapper">
      <!-- <template v-for="(item, index) in lista"> -->
      <cardView
        v-for="(item) in lista"
        :imageSrc="item.imagenGaleria"
        :imagetitle="item.titulo"
        :key="item.id"
        :idGallery="item.id"
        @iconClick="actionGallery"
      />
      <!-- </template> -->
    </div>
    <div class="loadingLogo" v-else>
      <b-spinner></b-spinner>
    </div>
    <editGallery
      :dataGallery="gallerySelected"
      v-if="editGallery"
      @updateGalleries="callReloadGallery"
    />
    <selectorImagenes
      :idGallery="idgallery"
      :multiSelect="true"
      id="selectorImagenEditGaleria"
    />
  </div>
</template>

<script>
import cardView from "@/components/dashboard/CardView.vue";
import axios from "@/directives/axios.js";
import editGallery from "@/components/galeria/EditGallery.vue";
import selectorImagenes from "@/components/dashboard/SelectorImages.vue";

export default {
  name: "createGallery",
  props: ["listMedia"],
  components: {
    cardView,
    editGallery,
    selectorImagenes,
  },
  data() {
    return {
      lista: [],
      editGallery: false,
      gallerySelected: undefined,
      idgallery: undefined,
    };
  },
  mounted() {
    this.callGallery();
  },
  methods: {
    callReloadGallery(value) {
      this.lista = [];
      this.lista = value;
      this.lista = this.lista.reverse();
      this.lista.push({
        imagenGaleria: require("@/assets/img/icon/plus-svgrepo-com.svg"),
        titulo: "Nueva Galeria",
        id: "0",
      });
    },
    callGallery() {
      axios({
        method: "GET",
        url: `/imagenes/todasGalerias`,
      })
        .then((data) => {
          this.lista = [];
          this.lista = data.data.todasGalerias;
          this.lista = this.lista.reverse();
          this.lista.push({
            imagenGaleria: require("@/assets/img/icon/plus-svgrepo-com.svg"),
            titulo: "Nueva Galeria",
            id: "0"
          });
        })
        .catch(() => {});
    },
    actionGallery(value) {
      switch (value.action) {
        case "openGallery":
          this.idgallery = value.id;
          this.$nextTick(() => {
            this.$bvModal.show("selectorImagenEditGaleria");
          });
          break;
        case "deleteGallery":
          this.deleteGallery(value.id);
          break;
        case "editGallery":
          this.gallerySelected = value;
          this.editGallery = true;
          this.$nextTick(() => {
            this.$bvModal.show("editGallery");
          });
          break;
        case "newGallery":
          this.gallerySelected = value;
          this.editGallery = true;
          this.$nextTick(() => {
            this.$bvModal.show("editGallery");
          });
          break;

        default:
          break;
      }
    },
    deleteGallery(id) {
      this.$bvModal
        .msgBoxConfirm("¿Desea eliminar esta Galeria y todas sus fotos?", {
          size: "sm",
          centered: true,
          footerClass: "justify-content-center",
          okTitle: "Si",
          cancelTitle: "No",
        })
        .then((option) => {
          if (option) {
            axios({
              method: "DELETE",
              url: `/imagenes/eliminarGaleria?id=${id}`,
              withCredentials: true,
            })
              .then((data) => {
                this.lista = [];
                this.lista = data.data.todasGalerias;
                this.lista = this.lista.reverse();
                this.lista.push({
                  backImage: require("@/assets/img/icon/plus-svgrepo-com.svg"),
                  titulo: "Nueva Galeria",
                  id: "0",
                  descripcion: "Crear nueva Galeria",
                });
              })
              .catch(() => {});
          }
        });
    },
  },
  computed: {
    getRoutePath() {
      return this.$router.path;
    },
  },
};
</script>
<style>
.wrapper {
  margin-left: 50px;
  margin-right: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: auto;
}
#bodyDashboard {
  transition: 0.5s;
  padding-top: 10px;
}
@media screen and (max-width: 600px) {
  .wrapper {
    margin-left: 20%;
    margin-right: 20%;
    display: block;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 600px) and (max-width: 900px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 900px) and (max-width: 1200px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1750px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
</style>
