import Vue from "vue";
import VueRouter from "vue-router";

import home from "@/views/website/paginaPrincipal/PaginaPrincipal.vue";
import vistaGaleria from "@/views/website/VistaGaleria.vue";
import vistaCarreteImagenes from "@/components/galeria/VistaCarreteImagenes.vue";
import vistaNoticias from "@/views/website/VistaNoticias.vue";
import vistaNoticia from "@/views/website/VistaNoticia.vue";
// import songs from "../views/songs.vue";
import homeWebSite from "../views/website/HomeWebSite.vue";
import vistaCaritasProyectos from "@/views/website/caritas/VistaCaritasProyectos.vue";
import vistaCaritasParroquial from "@/views/website/caritas/VistaCaritasParroquial.vue";
import vistaNoticiasCaritas from "@/views/website/caritas/VistaCaritasNoticias.vue"

import dashboard from "@/views/dashboard/Dashboard.vue";
import login from "@/views/dashboard/LoginView.vue";

Vue.use(VueRouter);

export const routes = [
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboard,
  },
  {
    path: "/",
    component: homeWebSite,
    children: [
      {
        path: "/",
        name: "inicio",
        component: home,
      },
      {
        path: "/CaritasProyectos",
        name: "CaritasProyectos",
        component: vistaCaritasProyectos,
      },
      {
        path: "/CaritasParroquial",
        name: "CaritasParroquial",
        component: vistaCaritasParroquial,
      },
      {
        path: "/CaritasParroquialNoticias",
        name: "CaritasParroquialNoticias",
        component: vistaNoticiasCaritas,
      },
      {
        path: "/noticia",
        name: "noticia",
        component: vistaNoticia,
        meta: { noCache: true },
      },
      {
        path: "/Multimedia",
        name: "Multimedia",
        component: vistaGaleria,
        meta: { noCache: true },
      },
      {
        path: "/carrete",
        name: "carrete",
        component: vistaCarreteImagenes,
        meta: { noCache: true },
      },
      {
        path: "/vistaNoticias",
        name: "vistaNoticias",
        component: vistaNoticias,
        meta: { noCache: true },
      },
    ],
  },
  // { path: "/songs", name: "songs", component: songs, meta: { nocache: false } },
  { path: "*", redirect: "/", name: "inicio" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
